// pages/UniversityListPage.tsx
import React, { useEffect, useState } from 'react';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../../auth/firebase';
import lucyLogo from '../../logo_lucy.png';
import { useNavigate } from 'react-router-dom';
import universitiesList from '../../data/us_universities.json';
import WaitlistPopup from '../../components/main_components/Popup/Popup_Waitlist';
import { sendUniversityRequestEmail } from '../../api/auth_and_onboarding';

interface University {
  name: string;
  signupLink?: string;
  logoUrl?: string;
}

const UniversityListPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [universitiesLucy, setUniversitiesLucy] = useState<University[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUniversities = async () => {
      const snapshot = await getDocs(collection(db, 'universities'));
      const uniList = snapshot.docs.map(doc => doc.data() as University);
      setUniversitiesLucy(uniList);
    };
    fetchUniversities();
  }, []);

  const handleSearch = (term: string) => {
    setSearchTerm(term);

    if (term.length > 1) {
      const matches = universitiesList
        .filter((uni) => uni.institution.toLowerCase().includes(term.toLowerCase()))
        .map((uni) => uni.institution)
        .slice(0, 10);
      setSuggestions(matches);
    } else {
      setSuggestions([]);
    }
  };

  const handleUniversityClick = (uni: University) => {
    if (uni.signupLink) {
      window.open(uni.signupLink, '_blank', 'noopener,noreferrer');
    } else {
      setSelectedUniversity(uni.name);
      setModalOpen(true);
    }
  };

  const handleAddUniversity = () => {
    if (!searchTerm) return;
    const existing = universitiesLucy.find(u => u.name.toLowerCase() === searchTerm.toLowerCase());

    if (existing) {
      handleUniversityClick(existing);
    } else {
      setSelectedUniversity(searchTerm);
      setModalOpen(true);
    }
  };

  const handleWaitlistSubmit = async (email: string) => {
    const existingUni = universitiesLucy.find(
      u => u.name.toLowerCase() === selectedUniversity.toLowerCase()
    );
  
    if (existingUni) {
      await addDoc(collection(db, 'waitlist'), { email, university: selectedUniversity });
      await sendUniversityRequestEmail(email, selectedUniversity);
    } else {
      await addDoc(collection(db, 'requested_universities'), { email, name: selectedUniversity });
      await sendUniversityRequestEmail(email, selectedUniversity);
    }
  
    setModalOpen(false);
    setSnackbarMessage("Your request has been received! We'll keep you updated by email.");
    setShowSnackbar(true);

    // Masquer la snackbar après 4 secondes :
    setTimeout(() => {
        setShowSnackbar(false);
    }, 4000);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="fixed top-0 left-0 right-0 flex items-center justify-center p-4 bg-white shadow-sm z-10">
        <img
          src={lucyLogo}
          alt="Lucy Logo"
          className="h-7 absolute left-4"
        />
        <h1 className="text-base md:text-lg font-medium text-gray-800 text-center px-12">
          Select your University
        </h1>
      </header>
  
      <main className="max-w-xl mx-auto pt-20 px-4 pb-10">
        <h2 className="text-base md:text-lg font-medium mb-3">
          Universities offering Lucy
        </h2>
        <div className="max-h-72 overflow-y-auto bg-white rounded-lg shadow-sm border">
          {universitiesLucy.map((uni, idx) => (
            <div
              key={idx}
              onClick={() => handleUniversityClick(uni)}
              className="flex items-center p-3 cursor-pointer hover:bg-gray-100 border-b last:border-b-0"
            >
              {uni.logoUrl && (
                <img
                  src={uni.logoUrl}
                  alt={`${uni.name} logo`}
                  className="w-7 h-7 rounded-full object-cover mr-2"
                />
              )}
              <span className="text-sm md:text-base">{uni.name}</span>
            </div>
          ))}
        </div>
  
        <h2 className="text-base md:text-lg font-medium mt-8 mb-3">
          Can't find your uni? Add it to bring Lucy to your campus!
        </h2>
        <input
          type="text"
          placeholder="Type your university name..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          className="w-full p-2.5 border rounded-lg text-sm md:text-base"
        />
  
        {suggestions.length > 0 && (
          <div className="border bg-white rounded-lg shadow-sm mt-2">
            {suggestions.map((suggestion, idx) => (
              <div
                key={idx}
                className="p-2.5 cursor-pointer hover:bg-gray-100 text-sm md:text-base"
                onClick={() => {
                  setSearchTerm(suggestion);
                  setSuggestions([]);
                }}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
  
        <button
          onClick={handleAddUniversity}
          className="mt-3 w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 text-sm md:text-base"
        >
          Join the Waitlist
        </button>
  
        {modalOpen && (
          <WaitlistPopup
            universityName={selectedUniversity}
            onClose={() => setModalOpen(false)}
            onSubmit={handleWaitlistSubmit}
          />
        )}
      </main>
  
      {showSnackbar && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-100 text-green-800 px-4 py-2 rounded-lg shadow-md text-sm text-center w-11/12 md:w-auto">
          {snackbarMessage}
        </div>
      )}
    </div>
  );
}

export default UniversityListPage;


