import React, { createContext, useState, useEffect, ReactNode } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../firebase"; // Assurez-vous que le chemin est correct
import { doc, getDoc } from 'firebase/firestore';
import {User} from '../../interfaces/interfaces_eleve'
import {AuthContextType} from '../../interfaces/interfaces_eleve'


// Création du contexte d'authentification
//export const AuthContext = createContext();
export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Fournisseur du contexte d'authentification
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  //const [user, setUser] = useState(null);
  const [user, setUser] = useState<User | null>(null);
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chatIds, setChatIds] = useState<string[]>([]);

  useEffect(() => {
    // Liste des routes publiques pour lesquelles l'authentification n'est pas nécessaire
    const publicPaths = ['/chatWidget'];
    const isPublicPath = publicPaths.includes(window.location.pathname);

    if (isPublicPath) {
      console.log("AuthProvider: Auth non requis pour", window.location.pathname);
      setLoading(false); // Pas besoin d'authentification pour cette route
      return;
    }

    console.log("AuthProvider: Initialisation de l'écouteur d'état d'authentification.");
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        console.log("AuthProvider: Utilisateur connecté:", currentUser);
        try {
          // Récupération des données utilisateur depuis Firestore
          const docRef = doc(db, 'users', currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUser({
              id: currentUser.uid,
              name: userData.name || '',
              email: currentUser.email || '',
              university: userData.university || '',
              faculty: userData.faculty || [],
              year: userData.year || '',
              academic_advisor: userData.academic_advisor || '',
              interests: userData.interests || [],
              major: userData.major || [],
              minor: userData.minor || [],
              role: userData.role || '',
              registered_club_status: userData.registered_club_status || '',
              registered_clubs: userData.registered_clubs || '',
              onboardingComplete: userData.onboardingComplete || true, // 🔹 Ajout de la nouvelle variable
              linkedin_url: userData.linkedin_url || '',  // ✅ Ajout de l'URL LinkedIn
              linkedin_profile: userData.linkedin_profile || {}, // ✅ Ajout des données LinkedIn récupérées
              onboardingMessageSent: userData.onboardingMessageSent || true, // 🔹 Ajout de la nouvelle variable
            });
            // Récupération des sessions de chat depuis Firestore
              const chatSessions = userData.chatsessions || [];
              console.log("This is the chatsessions:", chatSessions);
              // Définir les chatIds dans le contexte
              setChatIds(chatSessions);
              // Déterminer le dernier chat_id ou utiliser un identifiant par défaut
              const lastChatId = chatSessions.length > 0 ? chatSessions[chatSessions.length - 1] : 'default_chat_id_auth';
              console.log("Voici le chatId que l'on a récupéré de Firestore:", lastChatId);
              // Définir le chat_id principal avec le dernier chat_id
              setPrimaryChatId(lastChatId);
          } else {
            console.error("Données utilisateur non trouvées dans Firestore");
            setUser({
              id: currentUser.uid,
              email: currentUser.email || '',
              role: '',
              name: '',
              university: '',
              faculty: [],
              year: '',
              academic_advisor: '',
              interests: [],
              major: [],
              minor: [],
              registered_club_status: '',
              registered_clubs: '',
              onboardingComplete: true,
              linkedin_url: '',
              linkedin_profile: {},
              onboardingMessageSent: true
            });
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données utilisateur :", error);
          setUser({
            id: currentUser.uid,
            email: currentUser.email || '',
            role: '',
            name: '',
            university: '',
            faculty: [],
            year: '',
            academic_advisor: '',
            interests: [],
            major: [],
            minor: [],
            registered_club_status: '',
            registered_clubs: '',
            onboardingComplete: true,
            linkedin_url: '',
            linkedin_profile: {},
            onboardingMessageSent: true
          });
        }
        setIsAuth(true);
      } else {
        console.log("AuthProvider: Utilisateur déconnecté.");
        setUser(null);
        setIsAuth(false);
        setChatIds([]);
      }
      setLoading(false); // Fin du chargement après vérification de l'authentification
    });

    // Nettoyer l'écouteur lors du démontage du composant
    return () => {
      console.log("AuthProvider: Nettoyage de l'écouteur d'état d'authentification.");
      unsubscribe();
    };
  }, []);



  useEffect(() => {
    // Si l'utilisateur est défini et que le champ name est vide ou correspond à la valeur par défaut,
    // alors on refetch les données Firestore pour mettre à jour le contexte.
    if (user && (!user.name || user.name === 'default_username_OnSubmitFunction')) {
      const fetchUserData = async () => {
        try {
          const docRef = doc(db, 'users', user.id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const updatedData = docSnap.data();
            setUser((prevUser) => prevUser ? {
              ...prevUser,
              name: updatedData.name || prevUser.name,
              id: prevUser.id,
              email: prevUser.email,
              university: prevUser.university,
              faculty: prevUser.faculty,
              year: prevUser.year,
              academic_advisor: prevUser.academic_advisor,
              interests: prevUser.interests,
              major: prevUser.major,
              minor: prevUser.minor,
              role: prevUser.role,
              registered_club_status: prevUser.registered_club_status,
              registered_clubs: prevUser.registered_clubs,
              onboardingComplete: prevUser.onboardingComplete,
              linkedin_url: prevUser.linkedin_url,
              linkedin_profile: prevUser.linkedin_profile,
              onboardingMessageSent: prevUser.onboardingMessageSent
            } : null);
            console.log("AuthProvider: Contexte mis à jour avec les données Firestore:", updatedData);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour des données utilisateur depuis Firestore:", error);
        }
      };
      fetchUserData();
    }
  }, [user]);


  
  
  // Fonction pour définir le chat_id principal
  const setPrimaryChatId = (newChatId: string) => {
    setChatIds((prevChatIds) => {
      if (prevChatIds.length > 0) {
        const updatedChatIds = [...prevChatIds];
        updatedChatIds[0] = newChatId;
        return updatedChatIds;
      } else {
        return [newChatId];
      }
    });
    console.log(`AuthProvider: chat_id principal mis à jour dans le contexte: ${newChatId}`);
  };

  // Fonction pour ajouter un chat_id au contexte
  const addChatId = (chatId: string) => {
    setChatIds((prevChatIds) => [...prevChatIds, chatId]);
    console.log(`AuthProvider: chat_id ajouté au contexte: ${chatId}`);
  };

  // Fonction pour retirer un chat_id du contexte
  const removeChatId = (chatId: string) => {
    setChatIds((prevChatIds) => prevChatIds.filter(id => id !== chatId));
    console.log(`AuthProvider: chat_id retiré du contexte: ${chatId}`);
  };

  // Fonction de login (gérée par onAuthStateChanged)
  const login = () => {
    setIsAuth(true);
  };

  // Fonction de logout pour réinitialiser l'état de l'utilisateur
  const logout = async () => {
    try {
      console.log("AuthProvider: Tentative de déconnexion.");
      await signOut(auth);
      setUser(null);
      setIsAuth(false);
      setChatIds([]); // Réinitialiser les chat_ids lors de la déconnexion
      console.log("AuthProvider: Utilisateur déconnecté via logout.");
    } catch (error) {
      console.error("AuthProvider: Erreur lors de la déconnexion:", error);
    }
  };

  // Observer les changements de l'état du contexte et loguer les mises à jour
  useEffect(() => {
    console.log("AuthProvider: Contexte mis à jour:", {
      user,
      isAuth,
      loading,
      chatIds,
    });
  }, [user, isAuth, loading, chatIds]);

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      isAuth,
      setIsAuth,
      loading,
      chatIds,
      addChatId,
      removeChatId,
      setPrimaryChatId,
      login,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  );
};

